// webapp-totgresca/src/main/conponents/SpokenSectionList.js

import React, { useContext } from "react";
import { MainLayoutContext } from "../MainLayout";
import "../main.scss";

const SpokenSectionList = () => {
  const { state } = useContext(MainLayoutContext);

  return (
    <div className="sectionListCard">
      <ul>
        <p className="strikethrough">
          {" "}
          {/* Render list items */}
          {state.spokenSections.map((section, index) => (
            <li key={index}>{section}</li>
          ))}
        </p>
      </ul>
    </div>
  );
};

export default SpokenSectionList; // Export the component









