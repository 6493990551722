// webapp-totgresca/src/main/conponents/ControlPanel.js

import React, { useState } from "react";
import Button from "@mui/material/Button";
import InfoModal from "./InfoModal";
import ConfigModal from "./ConfigModal";
import Backdrop from "./Backdrop";
import "../main.scss";

function ControlPanel() {
  // State for tracking visibility of Info and Config modals
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [configModalOpen, setConfigModalOpen] = useState(false);

  // Handlers to open and close the Info modal
  const openInfoModalHandler = () => setInfoModalOpen(true);
  const closeInfoModalHandler = () => setInfoModalOpen(false);

  // Handlers to open and close the Config modal
  const openConfigModalHandler = () => setConfigModalOpen(true);
  const closeConfigModalHandler = () => setConfigModalOpen(false);

  return (
    <>
      {/* Button container with 'Info' and 'Config' buttons */}
      <div className="bottom-right-buttons-container">
        <Button
          variant="contained"
          onClick={openInfoModalHandler}
          className="btn-info"
        >
          Info
        </Button>
        <Button
          variant="contained"
          onClick={openConfigModalHandler}
          className="btn-config"
        >
          Config
        </Button>
      </div>

      {/* Render InfoModal and ConfigModal */}
      {infoModalOpen && <InfoModal onCancel={closeInfoModalHandler} />}
      {configModalOpen && <ConfigModal onCancel={closeConfigModalHandler} />}

      {/* Render Backdrop when either modal is open */}
      {(infoModalOpen || configModalOpen) && (
        <Backdrop
          onClick={() => {
            closeInfoModalHandler();
            closeConfigModalHandler();
          }}
        />
      )}
    </>
  );
}

export default ControlPanel;





