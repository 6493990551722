// webapp-totgresca/src/controller/components/SaveOrderButton.js

import React from "react";
import { Button } from "@mui/material";
import axios from "axios";
import "../controller.scss";

const SaveOrderButton = ({ sections, onOrderSaved, backendUrl, className }) => {
  const commitOrderChange = () => {
    console.log("Backend URL:", backendUrl);

    const apiPostUrl = `${backendUrl}/update_random_sections`;
    axios
      .post(apiPostUrl, { sections })
      .then((response) => {
        console.log(response.data);
        onOrderSaved(); // Callback to notify the parent component
      })
      .catch((error) => console.error("Error updating order:", error));
  };

  return (
    <Button className={className} onClick={commitOrderChange}>
      ACTUALITZAR
    </Button>
  );
}

export default SaveOrderButton;









