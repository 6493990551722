// webapp-totgresca/src/main/conponents/Countdown.js

import React, { useState, useEffect } from "react";
import { EVENTS, TIME_COUNTDOWN, TELECOGRESCA_VISIBLE, OTHER_VISIBLE } from "../constants";
import "../main.scss";

function Countdown() {
  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const [filteredEvents, setFilteredEvents] = useState([]);

  useEffect(() => {
    const updatedFilteredEvents = EVENTS.filter((event) => {
      const remainingDays = calculateRemainingDays(event.date);
      return event.name === "la Telecogresca"
        ? remainingDays >= 0 && remainingDays <= TELECOGRESCA_VISIBLE
        : remainingDays >= 0 && remainingDays <= OTHER_VISIBLE;
    });

    setFilteredEvents(updatedFilteredEvents);

    if (updatedFilteredEvents.length === 0) {
      return;
    }

    const intervalId = setInterval(() => {
      setCurrentEventIndex(
        (prevIndex) => (prevIndex + 1) % updatedFilteredEvents.length
      );
    }, TIME_COUNTDOWN * 1000); // Convert seconds to milliseconds

    return () => clearInterval(intervalId);
  }, []);

  const calculateRemainingDays = (eventDate) => {
    const currentDate = new Date();
    const eventDateObj = new Date(eventDate);
    const remainingTime = eventDateObj - currentDate;
    return Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  };

  const currentEvent = filteredEvents[currentEventIndex];

  return (
    <div className="countdownContainer">
      {currentEvent && (
        <div className="eventCountdown">
          <h2>
            Falten {calculateRemainingDays(currentEvent.date)} dies per{" "}
            {currentEvent.name}
          </h2>
        </div>
      )}
    </div>
  );
}

export default Countdown;




