// webapp-totgresca/src/main/conponents/ConfigModal.js

import axios from "axios";
import { BACKEND_URL } from "../constants";
import Button from "@mui/material/Button";
import "../main.scss";

function ConfigModal() {
  const handleResetSections = async () => {
    try {
      await axios.post(`${BACKEND_URL}/reset_sections`); // Reset sections
      window.location.reload(); // Refresh the page
    } catch (error) {
      console.error("Error resetting sections:", error);
    }
  };

  return (
    <div className="modal info-modal">
      <h2 className="modal-title">Configuració</h2>
      <ul className="keybinds-list">
          <Button
            onClick={handleResetSections}
            variant="contained"
            className="btn-reset"
          >
            <li>Redesordenar Seccions</li>
          </Button>
        <li>
          * Les seccions no es desordenen automàticament al refrecar la pàgina.
          Únicament ho fan al clicar aquest botó o una estona després d'acabar
          la Totgresca.
        </li>
          <Button
            onClick={null}
            variant="contained"
            className="btn-reset"
          >
            <li>Més configuració</li>
          </Button>
        <li>
          * Altres millores de configuració estan en costrucció.
        </li>
      </ul>
    </div>
  );
}

export default ConfigModal;





