// webapp-totgresca/src/main/conponents/SpeakingSectionDisplay.js

import React, { useContext } from "react";
import { MainLayoutContext } from "../MainLayout";
import "../main.scss";

const SpeakingSectionDisplay = () => {
  const { state } = useContext(MainLayoutContext);

  return (
    <div className="speakingSectionCard">
      {/* Display the current section */}
      <div>{state.currentSection}</div>
    </div>
  );
};

export default SpeakingSectionDisplay;








