// App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MainLayout from './main/MainLayout';
import ControllerLayout from './controller/ControllerLayout';

function App() {
  return (
      <Routes>
        <Route exact path="/" element={<MainLayout />} />
        <Route path="/controller" element={<ControllerLayout />} />
      </Routes>
  );
}

export default App;







