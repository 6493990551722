// webapp-totgresca/src/main/conponents/MoveSectionButton.js

import React, { useContext } from "react";
import axios from "axios";
import Button from "@mui/material/Button"; // Importing MaterialUI Button
import { MainLayoutContext } from "../MainLayout"; // Import the context from MainLayout
import { BACKEND_URL } from "../constants";
import "../main.scss";

const MoveSectionButton = () => {
  // Accessing state and dispatch from MainLayoutContext
  const { state, dispatch } = useContext(MainLayoutContext);

  // Function to move a section to 'speaking' in the backend
  const moveToSpeaking = async (section) => {
    try {
      await axios.post(`${BACKEND_URL}/move_to_speaking`, { section });
      console.log(`Moved section ${section} to speaking`);
    } catch (error) {
      console.error(`Error moving section ${section} to speaking`, error);
    }
  };

  // Handler for button click
  const handleButtonClick = async () => {
    if (state.currentSection) {
      // If there is a current section, move it to 'spoken'
      dispatch({
        type: "ADD_SPOKEN_SECTION",
        payload: state.currentSection,
      });
      dispatch({ type: "SET_CURRENT_SECTION", payload: null });

      if (!state.apiFailed) {
        // Only make the API call if apiFailed is false
        try {
          await axios.post(`${BACKEND_URL}/move_from_speaking_to_spoken`, {
            section: state.currentSection,
          });
        } catch (error) {
          console.error("Error moving section to spoken", error);
        }
        
      }
    } else {
      // If there isn't a current section, fetch a new one and move it to 'speaking'
      if (!state.apiFailed) {
        // Only make the API call if apiFailed is false
        try {
          const response = await axios.get(
            `${BACKEND_URL}/get_first_random_waiting_section`
          );
          const newSection = response.data.section;
          dispatch({ type: "SET_CURRENT_SECTION", payload: newSection });

          // Move the new section to 'speaking'
          await moveToSpeaking(newSection);

          // Update waiting sections list
          const updatedWaitingSections = state.waitingSections.filter(
            (section) => section !== newSection
          );
          dispatch({
            type: "SET_WAITING_SECTIONS",
            payload: updatedWaitingSections,
          });
        } catch (error) {
          console.error("Error fetching new section", error);
        }
      } else {
        // If API has failed
        if (state.waitingSections.length === 0 && state.escaEscaEsca === false) {
          // If the hardcoded list is empty
          dispatch({
            type: "SET_CURRENT_SECTION",
            payload: "ESCA! ESCA! ESCA!",
          });
          dispatch({
            type: "SET_ESCA_ESCA_ESCA",
            payload: true,
          });
        } else {
          // If the hardcoded list is not empty
          const randomIndex = Math.floor(
            Math.random() * state.waitingSections.length
          );
          const randomSection = state.waitingSections[randomIndex];
          dispatch({ type: "SET_CURRENT_SECTION", payload: randomSection });

          const updatedWaitingSections = state.waitingSections.filter(
            (section) => section !== randomSection
          );
          dispatch({
            type: "SET_WAITING_SECTIONS",
            payload: updatedWaitingSections,
          });
        }
      }
      dispatch({ type: "RESET_TIMER" }); // Reset the timer in all cases
    }
  };

  return (
    <div className="moveSectionButtonContainer">
      <Button
        variant="contained"
        onClick={handleButtonClick}
        className="moveSectionButton"
      />
    </div>
  );
};

export default MoveSectionButton;









