// webapp-totgresca/src/main/conponents/WaitingSectionList.js

import React, { useContext, useEffect } from "react";
import axios from "axios";
import { MainLayoutContext } from "../MainLayout";
import { BACKEND_URL } from "../constants";
import "../main.scss";

const WaitingSectionList = () => {
  const { state, dispatch } = useContext(MainLayoutContext);

  useEffect(() => {
    const fetchWaitingSections = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/get_waiting_sections`);
        console.log(`The API is working at ${BACKEND_URL}`); //Log the api is working and its ip

        dispatch({
          type: "SET_WAITING_SECTIONS",
          payload: response.data.waiting_sections,
        });
      } catch (error) {
        console.error("Error fetching waiting sections:", error);
        console.log("The API is not working");
        // Seting the waiting sections to a default list in case of error
        const defaultWaitingSections = [
          "Bar",
          "Càtering",
          "Comi Gènere",
          "CTU",
          "Engresca't",
          "Escenari",
          "Festa de Dia",
          "Finançament",
          "Instal·lació",
          "WIT",
          "Màrqueting",
          "Multimèdia",
          "Música",
          "Propaganda",
          "Seguretat i Logística",
          "VdE",
        ];
        dispatch({
          type: "SET_WAITING_SECTIONS",
          payload: defaultWaitingSections,
        });
        dispatch({
          type: "SET_API_FAILED",
          payload: true,
        });
      }
    };

    fetchWaitingSections();
  }, [dispatch]);

  return (
    <div className="sectionListCard">
      <ul>
        {/* Render list items */}
        {state.waitingSections.map((section, index) => (
          <li key={index}>{section}</li> // List item for each section
        ))}
      </ul>
    </div>
  );
};

export default WaitingSectionList;










