// webapp-totgresca/src/main/conponents/ImageDisplay.js

import React, { useContext } from "react";
import { MainLayoutContext } from "../MainLayout";
import "../main.scss";

const ImageDisplay = () => {
  const { state } = useContext(MainLayoutContext);

  const imageComponents = [
    { key: "espanya", isVisible: state.isEspanyaVisible },
    { key: "knife", isVisible: state.isKnifeVisible },
    { key: "estrellitas", isVisible: state.isEstrellitasVisible },
    { key: "flowers", isVisible: state.isFlowersVisible },
    { key: "monkey", isVisible: state.isMonkeyVisible },
    { key: "obama", isVisible: state.isObamaVisible },
    { key: "ratatouille", isVisible: state.isRatatouilleVisible },
    { key: "unoReverse", isVisible: state.isUnoReverseVisible },
    { key: "telecogresca", isVisible: state.isTelecogrescaVisible},
  ];

  return (
    <>
      {imageComponents.map((img) => (
        <div
          key={img.key}
          className={`image-${img.key} ${img.isVisible ? "" : "image-hidden"}`}
          style={{ display: img.isVisible ? "block" : "none" }}
        />
      ))}
    </>
  );
};

export default ImageDisplay;







