// webapp-totgresca/src/main/conponents/Title.js

import React, { useState, useEffect } from "react";
import "../main.scss";

function Title() {
  // State to store the current date
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    // Function to update the date every second
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 10000);

    // Cleanup function to clear the interval
    return () => {
      clearInterval(timer);
    };
  }, []); // Empty dependency array to run only once on mount

  // Function to format the date as 'DD-MM-YYYY'
  const formatDate = (date) => {
    return `${date.getDate().toString().padStart(2, "0")}-${
      (date.getMonth() + 1).toString().padStart(2, "0")}-${
        date.getFullYear()}`;
  };

  return (
    <div className="titleContainer">
      <h1>Reunió Totgresca {formatDate(currentDate)}</h1>
    </div>
  );
}

export default Title;








