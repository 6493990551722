// webapp-totgresca/src/main/MainLayout.js

import React, { useReducer, createContext } from "react";
import Title from "./components/Title";
import Countdown from "./components/Countdown";
import WaitingSectionList from "./components/WaitingSectionList";
import SpokenSectionList from "./components/SpokenSectionList";
import SpeakingSectionDisplay from "./components/SpeakingSectionDisplay";
import MoveSectionButton from "./components/MoveSectionButton";
import Timer from "./components/Timer";
import Keybind from "./components/Keybind";
import ThemeManager from "./components/ThemeManager";
import ControlPanel from "./components/ControlPanel";
import ImageDisplay from "./components/ImageDisplay";
import "./main.scss";

// Initial state for the application
const initialState = {
  apiFailed: false,
  escaEscaEsca: false,
  waitingSections: [],
  currentSection: null,
  spokenSections: [],
  isKnifeVisible: false,
  isEstrellitasVisible: false,
  isFlowersVisible: false,
  isMonkeyVisible: false,
  isObamaVisible: false,
  isRatatouilleVisible: false,
  isUnoReverseVisible: false,
  isTimerVisible: false,
  resetTimer: false,
  timerSeconds: 0,
  isEspanyaVisible: false,
};

// Reducer function to handle state updates based on actions
function reducer(state, action) {
  switch (action.type) {
    case "SET_API_FAILED":
      return { ...state, apiFailed: action.payload };
    case "SET_ESCA_ESCA_ESCA":
      return { ...state, escaEscaEsca: action.payload};
    case "SET_WAITING_SECTIONS": // Set waiting sections
      return { ...state, waitingSections: action.payload };
    case "SET_CURRENT_SECTION": // Set current section
      return { ...state, currentSection: action.payload };
    case "ADD_SPOKEN_SECTION": // Add spoken section
      return {
        ...state,
        spokenSections: [...state.spokenSections, action.payload],
      };
    case "RESET_TIMER": // Reset the Timer
      return { ...state, resetTimer: !state.resetTimer, timerSeconds: 0 };
    case "INCREMENT_TIMER": // Increment the Timer
      return { ...state, timerSeconds: state.timerSeconds + 1 };
    case "TOGGLE_ESPANYA_VISIBILITY": // Toggle visibility of Espanya
      return { ...state, isEspanyaVisible: !state.isEspanyaVisible };
    case "TOGGLE_TIMER_VISIBILITY": // Toggle visibility of the Timer
      return { ...state, isTimerVisible: !state.isTimerVisible };
    case "TOGGLE_KNIFE_VISIBILITY": // Toggle visibility of the knife
      return { ...state, isKnifeVisible: !state.isKnifeVisible };
    case "TOGGLE_ESTRELLITAS_VISIBILITY": // Toggle visibility of Estrellitas
      return { ...state, isEstrellitasVisible: !state.isEstrellitasVisible };
    case "TOGGLE_FLOWERS_VISIBILITY": // Toggle visibility of Flowers
      return { ...state, isFlowersVisible: !state.isFlowersVisible };
    case "TOGGLE_MONKEY_VISIBILITY": // Toggle visibility of Monkey
      return { ...state, isMonkeyVisible: !state.isMonkeyVisible };
    case "TOGGLE_OBAMA_VISIBILITY": // Toggle visibility of Obama
      return { ...state, isObamaVisible: !state.isObamaVisible };
    case "TOGGLE_RATATOUILLE_VISIBILITY": // Toggle visibility of Ratatouille
      return { ...state, isRatatouilleVisible: !state.isRatatouilleVisible };
    case "TOGGLE_UNO_REVERSE_VISIBILITY": // Toggle visibility of Uno Reverse
      return { ...state, isUnoReverseVisible: !state.isUnoReverseVisible };
    case "TOGGLE_TELECOGRESCA_VISIBILITY": // Toggle visibility of the final Telecogresca photo
      return { ...state, isTelecogrescaVisible: !state.isTelecogrescaVisible };
    default:
      return state;
  }
}

// Create a context to provide the state and dispatch function to child components
const MainLayoutContext = createContext();

function MainLayout() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <div className="main-layout">
      <MainLayoutContext.Provider value={{ state, dispatch }}>
        <ThemeManager />
        <Title />
        <Countdown />
        <MoveSectionButton />
        <div className="sectionListContainer">
          <WaitingSectionList />
          <div className="middleContainer">
            <SpeakingSectionDisplay />
            <Timer />
          </div>
          <SpokenSectionList />
        </div>
        <Keybind />

        {/* Meme image display component */}
        <ImageDisplay />

        {/* Control panel component */}
        <ControlPanel />
      </MainLayoutContext.Provider>
    </div>
  );
}

// Export the MainLayoutContext for child components to access the state
export { MainLayoutContext };
export default MainLayout;






