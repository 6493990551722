// webapp-totgresca/src/main/conponents/Backdrop.js

import "../main.scss";

// Renders a dark background for the modals to be more visible. It also handles closing the modals when clicked on
function Backdrop(props) { 
  return <div className="backdrop" onClick={props.onClick} />;
}

export default Backdrop;






