// webapp-totgresca/src/controller/components/MoveITToTopButton.js

import React from "react";
import { Button } from "@mui/material";
import "../controller.scss";

const MoveITToTopButton = ({ sections, setSections, className }) => {
  const moveITToTop = () => {
    const index = sections.findIndex((section) => section === "IT");
    if (index > -1) {
      const newSections = [...sections];
      newSections.splice(index, 1); // Remove 'IT'
      newSections.unshift("IT"); // Add 'IT' to the start
      setSections(newSections);
    }
  };

  return (
    <Button className={className} onClick={moveITToTop}>
      IT següent
    </Button>
  );
};

export default MoveITToTopButton;










