// webapp-totgresca/src/main/conponents/Timer.js

import React, { useContext, useEffect, useState } from "react";
import { MainLayoutContext } from "../MainLayout";
import {TIME_BLINKING, TIME_APPEAR, TIME_RED} from "../constants";
import "../main.scss";

function Timer() {
  const { state, dispatch } = useContext(MainLayoutContext);
  const { timerSeconds, isTimerVisible } = state;
  const [isBlinking, setIsBlinking] = useState(false);
  const [checkVisibility, setCheckVisibility] = useState(false);
  const [isRed, setIsRed] = useState(false);

  // Timer logic
  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch({ type: "INCREMENT_TIMER" });
    }, 1000);
    return () => clearInterval(intervalId);
  }, [dispatch]);

  // Blinking, Red color, and Visibility logic
  useEffect(() => {
    if (timerSeconds === TIME_BLINKING) {
      // 15'
      setIsBlinking(true);
      if (!isTimerVisible) {
        dispatch({ type: "TOGGLE_TIMER_VISIBILITY" });
        setCheckVisibility(true);
      }
      setTimeout(() => {
        setIsBlinking(false);
        if (!checkVisibility) {
          dispatch({ type: "TOGGLE_TIMER_VISIBILITY" });
        }
        setCheckVisibility(false);
      }, 5000);
    }
    if (timerSeconds === TIME_APPEAR) {
      // 20'
      if (!isTimerVisible) {
        dispatch({ type: "TOGGLE_TIMER_VISIBILITY" });
      }
    }
    if (timerSeconds === TIME_RED) {
      // 30'
      if (!isTimerVisible) {
        dispatch({ type: "TOGGLE_TIMER_VISIBILITY" });
      }
      setIsRed(true);
    }
    // Reset the timer's color when it's reset
    if (state.resetTimer) {
      setIsRed(false);
    }
  }, [timerSeconds, isTimerVisible, dispatch, checkVisibility, state.resetTimer]);

  // Function to format the seconds to MM:SS
  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (totalSeconds % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  let timerClass = `timerContainer ${isRed ? "timerContainerRed" : ""} ${
    isBlinking ? "blinking" : ""
  } ${isTimerVisible || checkVisibility ? "" : "invisible"}`;

  return <div className={timerClass}>{formatTime(timerSeconds)}</div>;
}

export default Timer;







