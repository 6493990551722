// webapp-totgresca/src/main/conponents/InfoModal.js

import "../main.scss";

function InfoModal(props) {
  return (
    <div className="modal info-modal">
      <h2 className="modal-title">Info de Keybinds</h2>
      <ul className="keybinds-list">
        <li>B - Espanya</li>
        <li>E - Estrellitas</li>
        <li>F - Flors</li>
        <li>K - Knife</li>
        <li>M - Mono</li>
        <li>O - Obama</li>
        <li>R - Ratatouille</li>
        <li>U - Uno Reverse</li>
        {/* Add more keybinds here in alphabetical order */}
      </ul>
    </div>
  );
}

export default InfoModal;






