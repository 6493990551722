// webapp-totgresca/src/main/constants.js

// Backend URL
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL; // || "http://localhost:4000"

// Time Constants for Timer
export const TIME_BLINKING = 900; // 15 minutes
export const TIME_APPEAR = 1200; // 20 minutes
export const TIME_RED = 1800; // 30 minutes

// Event Information
export const TIME_COUNTDOWN = 20; // 20 seconds
export const TELECOGRESCA_VISIBLE = 365; // Days 'till the event required to be shown onscreen
export const OTHER_VISIBLE = 90; // 3 months
export const EVENTS = [
  {
    name: "la Diada de Campus Nord", // The determinant is needed beacause Engresca't goes with l'
    date: "2024-10-27T23:30:00.000Z",
  },
  {
    name: "la Telecofarra",
    date: "2023-12-21T23:30:00.000Z",
  },
  {
    name: "l'Engresca't", 
    date: "2024-03-09T23:30:00.000Z",
  },
  {
    name: "la Festa de Dia", 
    date: "2024-03-20T23:30:00.000Z",
  },
  {
    name: "la Telecogresca", 
    date: "2024-04-06T18:00:00.000Z",
  },
  {
    name: "el Telecogreskids", 
    date: "2024-05-11T10:30:00.000Z",
  },
  // Add more events as needed
];





