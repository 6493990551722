// webapp-totgresca/src/main/conponents/Keybind.js

import { useEffect, useContext } from "react";
import { MainLayoutContext } from "../MainLayout";
import "../main.scss";

const Keybind = () => {
  const { dispatch } = useContext(MainLayoutContext);

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key.toLowerCase()) {
        case "t":
          dispatch({ type: "TOGGLE_TIMER_VISIBILITY" });
          break;
        case "k":
          dispatch({ type: "TOGGLE_KNIFE_VISIBILITY" });
          break;
        case "e":
          dispatch({ type: "TOGGLE_ESTRELLITAS_VISIBILITY" });
          break;
        case "f":
          dispatch({ type: "TOGGLE_FLOWERS_VISIBILITY" });
          break;
        case "m":
          dispatch({ type: "TOGGLE_MONKEY_VISIBILITY" });
          break;
        case "o":
          dispatch({ type: "TOGGLE_OBAMA_VISIBILITY" });
          break;
        case "r":
          dispatch({ type: "TOGGLE_RATATOUILLE_VISIBILITY" });
          break;
        case "u":
          dispatch({ type: "TOGGLE_UNO_REVERSE_VISIBILITY" });
          break;
        case "b":
          console.log("B key pressed");
          dispatch({ type: "TOGGLE_ESPANYA_VISIBILITY" });
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [dispatch]);

  return null;
};

export default Keybind;







