// webapp-totgresca/src/controller/components/SectionsManager.js

// Import necessary React hooks and components
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import socketIOClient from "socket.io-client";
// Import custom components that are used within this component
import DraggableSections from "./DraggableSections";
import SaveOrderButton from "./SaveOrderButton";
import MoveITToTopButton from "./MoveITToTopButton";
// Import the stylesheet used for styling this component and its children
import "../controller.scss";

// Define the SectionsManager component, which manages the sections of the draggable list
const SectionsManager = ({ backendUrl }) => {
  // useState hook to manage the state of sections within this component
  const [sections, setSections] = useState([]);

  // Construct the API URL using the backendUrl prop for fetching sections
  const apiGetUrl = `${backendUrl}/get_random_waiting_sections`;

  // Function to fetch sections from the API
  const fetchSections = useCallback(() => {
    // <-- Wrap with useCallback
    axios
      .get(apiGetUrl)
      .then((response) => {
        setSections(response.data.random_waiting_sections);
      })
      .catch((error) => {
        console.error("Error fetching sections:", error);
        setSections(["Error", "Backend", "Unavailable"]);
      });
  }, [apiGetUrl]);

  // useEffect hook to perform side effects such as fetching data from an API
  useEffect(() => {
    // Fetch the initial list of sections from the backend when the component mounts
    fetchSections();

    // Setup WebSocket connection
    const socket = socketIOClient(backendUrl);

    // Event listener for successful WebSocket connection
    socket.on("connect", () => {
      console.log("Connected to WebSocket");
    });

    // Event listener for WebSocket 'update' events
    socket.on("update", (data) => {
      console.log("Update received:", data);
      // Fetch the updated sections here or update state directly if the data contains necessary information
      fetchSections();
    });

    // Cleanup function to disconnect the WebSocket when the component unmounts
    return () => socket.disconnect();
  }, [backendUrl, apiGetUrl, fetchSections]); // Dependency array ensures this effect runs only when apiGetUrl or backendUrl changes

  // Function to handle the event when the order of sections is saved
  const handleOrderSaved = () => {
    console.log("Order has been saved.");
  };

  // Render the component
  return (
    // Container for the entire SectionsManager component
    <div className="container">
      <div className="card">
        <DraggableSections sections={sections} setSections={setSections} />
      </div>
      <div className="buttonsContainer">
        <div className="buttonContainer">
          <MoveITToTopButton
            sections={sections}
            setSections={setSections}
            className="button IT"
          />
        </div>
        <div className="buttonContainer">
          <SaveOrderButton
            sections={sections}
            onOrderSaved={handleOrderSaved}
            className="button actualitzar"
            backendUrl={backendUrl}
          />
        </div>
      </div>
    </div>
  );
};

// Export the SectionsManager component for use in other parts of the app
export default SectionsManager;







