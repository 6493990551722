// webapp-totgresca/src/controller/components/DraggableSections.js

import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { List, ListItem, ListItemText } from "@mui/material";
import "../controller.scss";

const DraggableSections = ({ sections, setSections }) => {
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = Array.from(sections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSections(items);
  };

  DraggableSections.defaultProps = {
    sections: [], // Default to an empty array if sections prop is not provided
  };


  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <List
            className="draggableList"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {sections.map((section, index) => (
              <Draggable key={section} draggableId={section} index={index}>
                {(provided) => (
                  <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="draggableListItem"
                  >
                    <ListItemText primary={section} />
                  </ListItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableSections;








