// webapp-totgresca/src/controller/ControllerLayout.js

import React from "react";
import SectionsManager from "./components/SectionsManager";
import "./controller.scss";

function ControllerLayout() {
  const backendUrl =
    process.env.REACT_APP_BACKEND_URL; // Remember to change this IP and the one on package.json to your computer's

  return (
    <div className="controller-layout">
      <div className="forcedBackground">
        <div className="container">
          <h1 className="title">Totgresca Controller</h1>
          <SectionsManager backendUrl={backendUrl} />
        </div>
      </div>
    </div>
  );
}

export default ControllerLayout;







