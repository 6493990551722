// webapp-totgresca/src/main/conponents/ThemeManager.js

import { useContext, useEffect, useState } from "react";
import { MainLayoutContext } from "../MainLayout";
import "../main.scss";

const ThemeManager = () => {
  const { state, dispatch } = useContext(MainLayoutContext);
  const [hasToggledEspanya, setHasToggledEspanya] = useState(false);
  const [hasToggledTelecogresca, setHasToggledTelecogresca] = useState(false);

  useEffect(() => {
    // Function to toggle Espanya visibility based on current section
    const toggleEspanyaVisibility = () => {
      if (
        state.currentSection === "Bar" &&
        !hasToggledEspanya &&
        !state.isEspanyaVisible
      ) {
        dispatch({ type: "TOGGLE_ESPANYA_VISIBILITY" });
        setHasToggledEspanya(true);
      } else if (
        state.currentSection === "Bar" && 
        !hasToggledEspanya
      ) {
        setHasToggledEspanya(true);
      } else if (
        state.currentSection !== "Bar" &&
        hasToggledEspanya &&
        state.isEspanyaVisible
      ) {
        dispatch({ type: "TOGGLE_ESPANYA_VISIBILITY" });
        setHasToggledEspanya(false);
      }
    };

    toggleEspanyaVisibility();

    // Apply theme based on the current section
    let themeClass = "theme-default";
    if (state.currentSection === "Engresca't") {
      themeClass = "theme-engrescat";
    } else if (state.currentSection === "Identitat i Gènere") {
      themeClass = "theme-comigenere";
    } else if (state.isEspanyaVisible) {
      themeClass = "theme-espanya";
    } else if (state.currentSection === "ESCA! ESCA! ESCA!" && hasToggledTelecogresca === false) {
      dispatch({ type: "TOGGLE_TELECOGRESCA_VISIBILITY" });
      setHasToggledTelecogresca(true);
    } else if (
      state.currentSection !== "ESCA! ESCA! ESCA!" &&
      hasToggledTelecogresca === true
    ) {
      dispatch({ type: "TOGGLE_TELECOGRESCA_VISIBILITY" });
      setHasToggledTelecogresca(false);
    }

    document.body.className = themeClass;
  }, [
    state.currentSection,
    state.isEspanyaVisible,
    dispatch,
    hasToggledEspanya,
    hasToggledTelecogresca,
  ]);

  // This component doesn't render anything, it just manages side effects
  return null;
};

export default ThemeManager;






